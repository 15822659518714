import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AutoComplete,
  Button,
  message,
  Modal,
  Select,
  Steps,
  Upload,
} from "antd";
import { InboxOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { FaLocationArrow, FaSearchLocation } from "react-icons/fa";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { LiaDrawPolygonSolid } from "react-icons/lia";
import {
  MdOutlineMyLocation,
  MdOutlineNextPlan,
  MdOutlineSatellite,
  MdOutlineUploadFile,
  MdPinDrop,
  MdRebaseEdit,
} from "react-icons/md";
import secureLocalStorage from "react-secure-storage";
import { motion } from "framer-motion";
import axios from "axios";
import shp from "shpjs";
import hostURL from "../../../Common/constants";
import { ModuleContext } from "../../../context/Context";
import drawPolygonVideo from "../../../videos/draw_polygon.mp4";
import uploadShapefileVideo from "../../../videos/upload_shapefile.mp4";
import dropPinVideo from "../../../videos/drop_pin.mp4";
import pickFieldVideo from "../../../videos/pick_field.mp4";
import "../style.css";

const Sidebar = ({
  mapZoomLevel,
  mapViewBounds,
  setSelectedFips,
  clickedState,
  clickedCounty,
  setZoomToCounty,
  userLocation,
  setUserLocation,
  pinCenter,
  setPinCenter,
  setFieldBoundary,
  selectedField,
  setSelectedField,
  setDrawingMode,
  editingMode,
  setEditingMode,
  setPinningMode,
  setLoading,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [countyData, setCountyData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCounty, setselectedCounty] = useState(null);
  const [addressValue, setAddressValue] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressObjects, setAddressObjects] = useState([]);
  const [inputMode, setInputMode] = useState(null);
  const [showDrawPolygonVideo, setShowDrawPolygonVideo] = useState(false);
  const [showUploadShapefileVideo, setShowUploadShapefileVideo] =
    useState(false);
  const [showDropPinVideo, setShowDropPinVideo] = useState(false);
  const [showPickFieldVideo, setShowPickFieldVideo] = useState(false);
  const [showPinAlert, setShowPinAlert] = useState(false);
  const [showFieldAlert, setShowFieldAlert] = useState(false);
  const { featureList, updateFieldDetails, practicePreference } =
    useContext(ModuleContext);
  const { Dragger } = Upload;

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  const hoverEffects = {
    opacity: 1,
    backgroundColor: "rgba(128, 0, 0, 0.4)",
  };

  const authToken = secureLocalStorage.getItem("token");

  const backToPracticeList = () => {
    navigate("/PracticeList");
  };

  const backToStep0 = () => {
    setZoomToCounty(false);
    setStep(0);
  };

  const backToStep1 = () => {
    setInputMode(null);
    setPinCenter(null);
    setFieldBoundary(null);
    setSelectedField(null);
    setDrawingMode(false);
    setPinningMode(false);
    setEditingMode(false);
  };

  const onStateChange = (selectedState) => {
    setLoading(true);
    setSelectedState(selectedState);
    setselectedCounty(null);
    fetch(hostURL + "api/counties?state=" + selectedState)
      .then((response) => response.json())
      .then((json) => {
        const countiesListData = json.map((item) => {
          return { value: item.cname, label: item.cname };
        });
        setCountyList(countiesListData);
        const countiesMetaData = json.map((item) => {
          return { fips: item.fips, state: item.scode, county: item.cname };
        });
        setCountyData(countiesMetaData);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const onCountyChange = (selectedCounty) => {
    let countyFipsCode = countyData.filter(
      (item) => item.county === selectedCounty
    )[0].fips;
    setSelectedFips(countyFipsCode);
    setselectedCounty(selectedCounty);
  };

  const onCountySubmit = () => {
    setZoomToCounty(true);
    setStep(1);
  };

  const getLocationDetails = async (longitude, latitude) => {
    const data = {
      type: "Point",
      coordinates: [longitude, latitude],
    };
    const response = await axios.post(hostURL + "api/field/details", { data });
    if (response.data) {
      setUserLocation({
        ...userLocation,
        state: response.data.countyRate.rows[0].state,
        county: response.data.countyRate.rows[0].county,
      });
    }
  };

  const locationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const onLocationSuccess = (location) => {
    var coordinates = location.coords;
    setUserLocation({
      longitude: coordinates.longitude,
      latitude: coordinates.latitude,
    });
    getLocationDetails(coordinates.longitude, coordinates.latitude);
    setStep(1);
  };

  const onLocationError = (error) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              onLocationSuccess,
              onLocationError,
              locationOptions
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              onLocationSuccess,
              onLocationError,
              locationOptions
            );
          } else if (result.state === "denied") {
            console.log(result);
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const getAddressOptions = async (address) => {
    const response = await axios.get(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        address +
        ".json?proximity=ip&access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
    );
    if (response.data) {
      setAddressObjects(response.data);
      const options = response.data.features.map((feature) => {
        return { key: feature.id, value: feature.place_name };
      });
      return options;
    }
  };

  const onAddressChange = async (value) => {
    setAddressValue(value);
    if (value) {
      const options = await getAddressOptions(value);
      setAddressOptions(options);
    }
  };

  const onAddressSubmit = () => {
    const addressFeature = addressObjects.features.filter(
      (feature) => feature.place_name === addressValue
    )[0];
    setUserLocation({
      longitude: addressFeature.center[0],
      latitude: addressFeature.center[1],
    });
    getLocationDetails(addressFeature.center[0], addressFeature.center[1]);
    setStep(1);
  };

  const loadPinnedField = async () => {
    const data = {
      type: "Point",
      coordinates: [pinCenter.lng, pinCenter.lat],
    };

    try {
      const response = await axios.post(hostURL + "api/field", { data });
      const fieldBoundaryFeatures = response.data.field.rows.map((feature) => {
        const geometry = JSON.parse(feature.geometry);
        return {
          type: "Feature",
          geometry: geometry,
          id: feature.gid,
          properties: { gid: feature.gid },
        };
      });
      if (fieldBoundaryFeatures.length === 0) {
        setShowPinAlert(true);
      } else {
        const fieldBoundaryData = fieldBoundaryFeatures[0];
        setFieldBoundary(fieldBoundaryData);
        setSelectedField(fieldBoundaryData);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const loadFieldBoundary = async () => {
    const data = {
      type: "Polygon",
      coordinates: [
        [
          [mapViewBounds._ne.lng, mapViewBounds._ne.lat],
          [mapViewBounds._ne.lng, mapViewBounds._sw.lat],
          [mapViewBounds._sw.lng, mapViewBounds._sw.lat],
          [mapViewBounds._sw.lng, mapViewBounds._ne.lat],
          [mapViewBounds._ne.lng, mapViewBounds._ne.lat],
        ],
      ],
    };

    try {
      const response = await axios.post(hostURL + "api/field", { data });
      const fieldBoundaryFeatures = response.data.field.rows.map((feature) => {
        const geometry = JSON.parse(feature.geometry);
        return {
          type: "Feature",
          geometry: geometry,
          id: feature.gid,
          properties: { gid: feature.gid },
        };
      });
      if (fieldBoundaryFeatures.length === 0) {
        setShowFieldAlert(true);
      } else {
        const fieldBoundaryData = {
          type: "FeatureCollection",
          features: fieldBoundaryFeatures,
        };
        setFieldBoundary(fieldBoundaryData);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const editField = () => {
    setEditingMode(true);
  };

  const submitField = async () => {
    if (selectedField) {
      const data = selectedField.geometry;

      try {
        setLoading(true);
        const response = await axios.post(hostURL + "api/field/details", {
          data,
        });
        updateFieldDetails(response.data);
        secureLocalStorage.setItem("field-details", response.data);
        setDrawingMode(false);
        navigate("/FieldReport");
        setLoading(false);
      } catch (error) {
        console.log(error.response.data);
      }
    }
  };

  const submitDraw = async () => {
    if (featureList.length === 0) {
      // setAlerttext("At least one polygon is required.");
    } else {
      // setAlerttext(false);
      const newList = featureList;
      const data = {
        type: "MultiPolygon",
        coordinates: newList.map((feature) => feature.geometry.coordinates),
      };

      try {
        setLoading(true);
        const response = await axios.post(hostURL + "api/field/details", {
          data,
        });
        updateFieldDetails(response.data);
        secureLocalStorage.setItem("field-details", response.data);
        setDrawingMode(false);
        navigate("/FieldReport");
        setLoading(false);
      } catch (error) {
        console.log(error.response.data);
      }
    }
  };

  const submitShapefile = async (geometry) => {
    const data = geometry;

    try {
      setLoading(true);
      const response = await axios.post(hostURL + "api/field/details", {
        data,
      });
      updateFieldDetails(response.data);
      secureLocalStorage.setItem("field-details", response.data);
      setDrawingMode(false);
      navigate("/FieldReport");
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const onFileDrop = async (file) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const result = await shp(reader.result);
      if (result) {
        // Features are stored as [0:{}, 1:{}, 2:{}, ...]
        for (var num in result.features) {
          var featureGeometry = result.features[num].geometry;
          // Add geometry type as a parameter to cater to both Polygon and MultiPolygon
          submitShapefile(featureGeometry);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    onFileDrop(file);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadProps = {
    name: "file",
    accept: ".zip",
    multiple: false,
  };

  useEffect(() => {
    setLoading(true);
    fetch(hostURL + "api/states", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const statesListData = json.map((item) => {
          return { value: item.scode, label: item.sname };
        });
        setStateList(statesListData);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    onStateChange(clickedState);
    setSelectedState(clickedState);
    setselectedCounty(clickedCounty);
  }, [clickedState, clickedCounty]);

  useEffect(() => {
    if (pinCenter) {
      loadPinnedField();
    }
  }, [pinCenter]);

  return (
    <div className="sidebar">
      <Steps current={step} size="small" />
      {step === 0 && (
        <>
          {userLocation.state ? (
            <h3>
              Your current location is
              <br />
              <b style={{ color: "maroon" }}>{userLocation.county} County, </b>
              <b style={{ color: "maroon" }}>{userLocation.state}</b>
            </h3>
          ) : practicePreference ? (
            <h3>
              You have previously selected
              <br />
              <b style={{ color: "maroon" }}>
                {practicePreference.county} County,{" "}
              </b>
              <b style={{ color: "maroon" }}>{practicePreference.state}</b>
            </h3>
          ) : (
            <h3>Let's find your field area</h3>
          )}
          <motion.div
            className="sidebar-section-1"
            variants={variants}
            whileHover={hoverEffects}
          >
            <p>
              <b>
                Please select the state and county of your field below or click
                on the map:
              </b>
            </p>
            <Select
              className="sidebar-select"
              showSearch
              defaultValue={selectedState}
              placeholder="Select State"
              onChange={onStateChange}
              options={stateList}
              value={
                selectedState ||
                (stateList.length > 0 ? "Select State" : "No State")
              }
            />
            <Select
              className="sidebar-select"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder="Select County"
              defaultValue={selectedCounty}
              onChange={onCountyChange}
              options={countyList}
              value={
                selectedCounty ||
                (countyList.length > 0
                  ? "Select County"
                  : selectedState != null
                  ? "No County"
                  : "")
              }
            />
            <br />
            <div className="button-container-end">
              <Button className="submit-button" onClick={onCountySubmit}>
                <FaLocationArrow className="button-icon" />
                <b> Locate</b>
              </Button>
            </div>
          </motion.div>
          <div className="sidebar-section-break">
            <p>
              <b>OR</b>
            </p>
          </div>
          <motion.div
            className="sidebar-section-2"
            variants={variants}
            whileHover={hoverEffects}
          >
            <p>
              <b>
                Please use your current location or search by address of your
                field:
              </b>
            </p>
            <Button className="rectangular-button" onClick={getUserLocation}>
              <MdOutlineMyLocation className="button-icon" />
              <b> Use Current Location</b>
            </Button>
            <AutoComplete
              className="address-input"
              value={addressValue}
              options={addressOptions}
              onChange={onAddressChange}
              placeholder="Search the address here"
              allowClear={true}
            />
            <Button className="submit-button" onClick={onAddressSubmit}>
              <FaSearchLocation className="button-icon" />
              <b> Locate</b>
            </Button>
          </motion.div>
          <div className="button-container">
            <Button className="back-button" onClick={backToPracticeList}>
              <GrLinkPrevious />
              <b> To Explore CRP Practices</b>
            </Button>
          </div>
        </>
      )}

      {step === 1 && (
        <>
          {!inputMode && (
            <>
              <h4>
                Please select one of the options below to outline, import or
                pick your field boundary:
              </h4>
              <div>
                <Button
                  className="square-button"
                  onClick={() => {
                    setInputMode("draw");
                    setDrawingMode(true);
                  }}
                >
                  <LiaDrawPolygonSolid size={"40px"} />
                  <br />
                  <b>Draw Your</b>
                  <br />
                  <b>Field Boundary</b>
                </Button>
                <Button
                  className="square-button"
                  onClick={() => setInputMode("upload")}
                >
                  <MdOutlineUploadFile size={"40px"} />
                  <br />
                  <b>Upload Your</b>
                  <br />
                  <b>Field Boundary</b>
                </Button>
                <Button
                  className="square-button"
                  onClick={() => {
                    setInputMode("pin");
                    setPinningMode(true);
                  }}
                  disabled={true}
                >
                  <MdPinDrop size={"40px"} />
                  <br />
                  <b>Drop Your Pin</b>
                  <br />
                  <b>{"(Coming Soon)"}</b>
                </Button>
                <Button
                  className="square-button"
                  onClick={() => {
                    setInputMode("field");
                    loadFieldBoundary();
                  }}
                  disabled={true}
                >
                  <MdOutlineSatellite size={"40px"} />
                  <br />
                  <b>Pick From Fields</b>
                  <br />
                  <b>{"(Coming Soon)"}</b>
                </Button>
              </div>
              <div className="button-container">
                <Button className="back-button" onClick={backToStep0}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
              </div>
            </>
          )}

          {inputMode === "draw" && (
            <div className="draw-instruction">
              <p>
                <b>
                  Please draw the boundary of your field on the map and click
                  Submit once it's ready.{" "}
                </b>
              </p>
              <div className="instruction-container">
                <Button
                  className="instruction-video-button"
                  type="primary"
                  onClick={() => {
                    setShowDrawPolygonVideo(true);
                  }}
                >
                  <VideoCameraOutlined />
                  Watch the tutorial video
                </Button>
                <p className="instruction-paragraph">
                  <u>
                    <b>Instructions</b>
                  </u>
                  : Click on the map to begin drawing your field boundary, then
                  click as you draw each field corner to complete your field
                  shape. Once your field boundary is finished, click the{" "}
                  <i>polygon</i> icon again to make changes. Click the{" "}
                  <i>delete</i> icon to delete your field boundary. Press the
                  ESC button to cancel the drawing process.
                </p>
              </div>
              <Modal
                open={showDrawPolygonVideo}
                onCancel={() => {
                  setShowDrawPolygonVideo(false);
                }}
                onOk={() => {
                  setShowDrawPolygonVideo(false);
                }}
              >
                <video
                  className="instruction-video"
                  width="400px"
                  height="225px"
                  controls
                >
                  <source src={drawPolygonVideo} type="video/mp4"></source>
                </video>
              </Modal>
              <div className="button-container">
                <Button className="back-button" onClick={backToStep1}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
                <Button className="submit-button" onClick={submitDraw}>
                  <b>Submit </b>
                  <GrLinkNext />
                </Button>
              </div>
            </div>
          )}

          {inputMode === "upload" && (
            <div className="upload-instruction">
              <p>
                <b>
                  Please drag and drop the zipped shapefile for your field
                  boundary here.
                </b>
              </p>
              <div className="instruction-container">
                <Button
                  className="instruction-video-button"
                  type="primary"
                  onClick={() => {
                    setShowUploadShapefileVideo(true);
                  }}
                >
                  <VideoCameraOutlined />
                  Watch the tutorial video
                </Button>
                <p className="instruction-paragraph">
                  <u>
                    <b>Instructions</b>
                  </u>
                  : Collect the shapefiles of your field in the same folder
                  (must include .dbf, .prj, .shp and .shx files) and zip them
                  into a .zip file. Upload or drag the .zip file into this drop
                  area.
                </p>
              </div>
              <Modal
                open={showUploadShapefileVideo}
                onCancel={() => {
                  setShowUploadShapefileVideo(false);
                }}
                onOk={() => {
                  setShowUploadShapefileVideo(false);
                }}
              >
                <video
                  className="instruction-video"
                  width="400px"
                  height="225px"
                  controls
                >
                  <source src={uploadShapefileVideo} type="video/mp4"></source>
                </video>
              </Modal>
              <Dragger {...uploadProps} customRequest={dummyRequest}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single zipped shapefile upload. Strictly
                  prohibited from uploading malicious data or other unrelated
                  files.
                </p>
              </Dragger>
              <div className="button-container">
                <Button className="back-button" onClick={backToStep1}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
              </div>
            </div>
          )}

          {inputMode === "pin" && (
            <div className="pin-instruction">
              {selectedField ? (
                <>
                  <p>
                    <b>
                      You have dropped the pin above one of the pregenerated
                      field boundaries. Please select one of the following
                      options:
                    </b>
                  </p>
                  <p>
                    <Button
                      className="square-button"
                      onClick={editField}
                      disabled={editingMode ? true : false}
                    >
                      <MdRebaseEdit size={"40px"} />
                      <br />
                      <b>Edit Selected</b>
                      <br />
                      <b>Field Boundary</b>
                    </Button>
                    <Button className="square-button" onClick={submitField}>
                      <MdOutlineNextPlan size={"40px"} />
                      <br />
                      <b>Proceed With</b>
                      <br />
                      <b>Selected Field</b>
                    </Button>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <b>
                      Please drop a pin on the map to locate a pregenerated
                      field boundary.
                    </b>
                  </p>
                  <div className="instruction-container">
                    <Button
                      className="instruction-video-button"
                      type="primary"
                      onClick={() => {
                        setShowDropPinVideo(true);
                      }}
                    >
                      <VideoCameraOutlined />
                      Watch the tutorial video
                    </Button>
                    <p className="instruction-paragraph">
                      <u>
                        <b>Instructions</b>
                      </u>
                      : Click on the map to drop a pin when you see the cursor
                      turns into a cross and wait for the field boundary to
                      load. If you see an error, it means there is no
                      pregenerated field boundary at the exact pin location.
                      Once the field boundary is loaded, choose one of the two
                      options to edit the polygon or proceed with the field once
                      ready.
                    </p>
                  </div>
                  <Modal
                    width="1070px"
                    open={showDropPinVideo}
                    onCancel={() => {
                      setShowDropPinVideo(false);
                    }}
                    onOk={() => {
                      setShowDropPinVideo(false);
                    }}
                  >
                    <video
                      className="instruction-video"
                      width="950px"
                      height="360px"
                      controls
                    >
                      <source src={dropPinVideo} type="video/mp4"></source>
                    </video>
                  </Modal>
                  {showPinAlert && (
                    <Alert
                      message="Error"
                      description="The pin you dropped doesn't overlap with any of the pregenerated field boundaries. Please try a different location or choose another option to outline or import your field."
                      type="error"
                      showIcon
                      closable
                    />
                  )}
                </>
              )}
              <div className="button-container">
                <Button className="back-button" onClick={backToStep1}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
              </div>
            </div>
          )}

          {inputMode === "field" && (
            <div className="field-instruction">
              {selectedField ? (
                <>
                  <p>
                    <b>
                      You have selected one field from the pregenerated field
                      boundaries. Please select one of the following options:
                    </b>
                  </p>
                  <p>
                    <Button
                      className="square-button"
                      onClick={editField}
                      disabled={editingMode ? true : false}
                    >
                      <MdRebaseEdit size={"40px"} />
                      <br />
                      <b>Edit Selected</b>
                      <br />
                      <b>Field Boundary</b>
                    </Button>
                    <Button className="square-button" onClick={submitField}>
                      <MdOutlineNextPlan size={"40px"} />
                      <br />
                      <b>Proceed With</b>
                      <br />
                      <b>Selected Field</b>
                    </Button>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <b>
                      Please pick your field from the pregenerated field
                      boundaries:
                    </b>
                  </p>
                  <div className="instruction-container">
                    <Button
                      className="instruction-video-button"
                      type="primary"
                      onClick={() => {
                        setShowPickFieldVideo(true);
                      }}
                    >
                      <VideoCameraOutlined />
                      Watch the tutorial video
                    </Button>
                    <p className="instruction-paragraph">
                      <u>
                        <b>Instructions</b>
                      </u>
                      : Click on the map to select one of the pregenerated
                      fields. If you see an error, it means there is no
                      pregenerated field boundary within the current map view.
                      Once the field boundary is selected, choose one of the two
                      options to edit the polygon or proceed with the field once
                      ready.
                    </p>
                  </div>
                  <Modal
                    width="1070px"
                    open={showPickFieldVideo}
                    onCancel={() => {
                      setShowPickFieldVideo(false);
                    }}
                    onOk={() => {
                      setShowPickFieldVideo(false);
                    }}
                  >
                    <video
                      className="instruction-video"
                      width="950px"
                      height="360px"
                      controls
                    >
                      <source src={pickFieldVideo} type="video/mp4"></source>
                    </video>
                  </Modal>
                  {showFieldAlert && (
                    <Alert
                      message="Error"
                      description="There is no pregenerated field boundary under your current map view. Please zoom out and retry or choose another option to outline or import your field."
                      type="error"
                      showIcon
                      closable
                    />
                  )}
                </>
              )}
              <div className="button-container">
                <Button className="back-button" onClick={backToStep1}>
                  <GrLinkPrevious />
                  <b> Back</b>
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Sidebar;
