export const getPracticePriorities = (prioritiesList) => {
  let filteredPriorities = prioritiesList.filter((priority) => {
    return (
      priority !== "cropland" &&
      priority !== "timber" &&
      priority !== "pasture" &&
      priority !== "wetland" &&
      priority !== "aquaculture"
    );
  });
  return filteredPriorities;
};

export const getPracticeLandCovers = (prioritiesList) => {
  let filteredPriorities = prioritiesList.filter((priority) => {
    return (
      priority !== "aquatic" &&
      priority !== "biggame" &&
      priority !== "carbonstorage" &&
      priority !== "drought" &&
      priority !== "watertemperature" &&
      priority !== "excesswindflooding" &&
      priority !== "forestbirds" &&
      priority !== "grasslandbirds" &&
      priority !== "pollinators" &&
      priority !== "snowdrift" &&
      priority !== "soilhealth" &&
      priority !== "waterfowl" &&
      priority !== "waterquality" &&
      priority !== "winderosion" &&
      priority !== "watererosion" &&
      priority !== "flooding" 
     );
  });
  return filteredPriorities;
};

export const getPracticeIncentives = (practiceData) => {
  let practiceIncentives = [];
  if (practiceData) {
    practiceData["20_p_rr_incentive"] === true &&
      practiceIncentives.push("20_p_rr_incentive");
    practiceData["32_5_p_sip"] === true &&
      practiceIncentives.push("32_5_p_sip");
    practiceData["50_p_pip"] === true && practiceIncentives.push("50_p_pip");
    practiceData["climate_smrt_incentive"] !== false &&
      practiceData["climate_smrt_incentive"] !== "false" &&
      practiceIncentives.push(practiceData["climate_smrt_incentive"]);
  }
  return practiceIncentives;
};
