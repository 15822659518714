import React, { useEffect, useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";
import { Col, Row, Menu, Modal, notification, Dropdown, Space } from "antd";
import {
  UnorderedListOutlined,
  UserOutlined,
  LogoutOutlined,
  ProfileOutlined,
  DownOutlined
} from "@ant-design/icons";
import hostURL from "../../Common/constants";
import logo from "../../images/header/HORIZONTAL_web_white.png";
import "./style.css";

function Header({ noNavigation }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [toShowSpinner, setToShowSpinner] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const isLoggedIn = secureLocalStorage.getItem("token");

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };
  const activeMenu = window.location.pathname.substring(1);
  const items = [
    {
      label: <a href="/">Home</a>,
      key: "home",
      style: { fontWeight: "bold" },
      className: activeMenu === "" ? "activeMenu" : "",
    },
    {
      label: <a href="/CRPMenu">About</a>,
      key: "crpMenu",
      style: { fontWeight: "bold" },
      className: activeMenu === "CRPMenu" ? "activeMenu" : "",
    },
    {
      label: <a href="/CRPBasics">CRP Basics</a>,
      key: "crpBasics",
      style: { fontWeight: "bold" },
      className: activeMenu === "CRPBasics" ? "activeMenu" : "",
    },
    {
      label: <a href="/PracticeList">Explore Practices</a>,
      key: "explorePractices",
      style: { fontWeight: "bold" },
      className: activeMenu === "PracticeList" ? "activeMenu" : "",
    },
    {
      label: <a href="/Map">Check Eligibility</a>,
      key: "checkEligibility",
      style: { fontWeight: "bold" },
      className: activeMenu === "Map" ? "activeMenu" : "",
    },
    {
      label: <a href="/Workshops">Workshops</a>,
      key: "workshops",
      style: { fontWeight: "bold" },
      className: activeMenu === "Workshops" ? "activeMenu" : "",
    },
    {
      label: <a href="/ProjectTeam">Team</a>,
      key: "projectTeam",
      style: { fontWeight: "bold" },
      className: activeMenu === "ProjectTeam" ? "activeMenu" : "",
    },
    {
      label: <a href="/Contact">Contact Us</a>,
      key: "contact",
      style: { fontWeight: "bold" },
      className: activeMenu === "Contact" ? "activeMenu" : "",
    },
  ];

  const userMenuItems = [
    {
      label: (
        <a href="/UserProfile">
          <ProfileOutlined /> Profile
        </a>
      ),
      key: "uhome",
      style: { fontWeight: "bold" },
    },
    {
      label: (
        <a onClick={() => showLogoutModal()}>
          <LogoutOutlined /> Logout
        </a>
      ),
      key: "ucrpMenu",
      style: { fontWeight: "bold" },
    },
  ];

  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const handleLogoutOk = () => {
    Signout();
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectedMenuItem = (itemData) => {
    setSelectedItem(itemData.selectedKeys);
  };

  const Signout = async () => {
    setToShowSpinner(true);
    await fetch(hostURL + "api/users/signOut", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(isLoggedIn)
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
            secureLocalStorage.clear();
            sessionStorage.clear();
            console.clear();
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setToShowSpinner(false);
        if (status === 200) {
          openNotificationWithIcon("success", "Success", message);
          document.cookie =
            "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          secureLocalStorage.removeItem("token");
          secureLocalStorage.removeItem("userRef");
          window.location.assign("/");
        } else openNotificationWithIcon("error", "Error", message);
      });
  };

  return [
    <>
      <div className="msu_header">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 16, offset: 1 }}>
            <Row>
              <a href="/">
                <h1 className="header-tool-name">CRP Menu Tool</h1>
              </a>
            </Row>
          </Col>
          <Col
            className="gri-logo"
            xs={{ span: 24 }}
            lg={{ span: 4, offset: 2 }}
          >
            <a href="https://www.gri.msstate.edu/" rel="noreferrer" target="_blank">
              <img className="header-logo" src={`${logo}`} alt="MSU Logo" />
            </a>
          </Col>
        </Row>
      </div>
      {noNavigation !== "print" && (
        <Row className="header-nav">
          <Col xs={{ span: 24 }} lg={{ span: 21, offset: 1 }}>
            <button className="crpMobileNavIcon" onClick={showModal}>
              <UnorderedListOutlined />
            </button>
            <Menu
              className="crpmainnav"
              mode="horizontal centered"
              items={items}
              onSelect={selectedMenuItem}
              breakpoint=""
              defaultSelectedKeys={items[0]}
              selectedKeys={[selectedItem]}
            />
            {(isLoggedIn === null || isLoggedIn.length === 0) &&
              noNavigation !== "print" && (
                <h6 className="register-login-link">
                  <a href="/UserLogin">
                    <UserOutlined /> Register / Login
                  </a>
                </h6>
              )}
            <Modal
              title="Menu"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
            >
              <Menu mode="inline" items={items} />
            </Modal>
            {isLoggedIn !== null && isLoggedIn.length > 24 && (
              <Dropdown.Button
                className="loggedInMenu"
                icon={<DownOutlined />}
                menu={{
                  items: userMenuItems,
                }}
              >
                <UserOutlined />
              </Dropdown.Button>
            )}
          </Col>
        </Row>
      )}
      <>
        <Modal
          title="Logout Confirmation"
          open={isLogoutModalOpen}
          onOk={handleLogoutOk}
          onCancel={handleLogoutCancel}
          okText="Ok"
          cancelText="Cancel"
        >
          <p>
            <center>Are you sure to log out?</center>
          </p>
        </Modal>
      </>
      {contextHolder}
    </>,
  ];
}

export default Header;
