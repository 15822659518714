import React, { forwardRef, useEffect, useState } from "react";
import { Col, Popover, Row } from "antd";
import secureLocalStorage from "react-secure-storage";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import aquatic from "../../../images/priority/Aquatic.png";
import biggame from "../../../images/priority/BigGame.png";
import carbonstorage from "../../../images/priority/CarbonStorage.png";
import drought from "../../../images/priority/Drought.png";
import watertemperature from "../../../images/priority/WaterTemperature.png";
import excesswind from "../../../images/priority/ExcessWind.png";
import flooding from "../../../images/priority/Flooding.png";
import forestbirds from "../../../images/priority/ForestBirds.png";
import grasslandbirds from "../../../images/priority/GrasslandBirds.png";
import pollinators from "../../../images/priority/Pollinators.png";
import snowdrift from "../../../images/priority/SnowDrift.png";
import soilhealth from "../../../images/priority/SoilHealth.png";
import watererosion from "../../../images/priority/WaterErosion.png";
import waterfowl from "../../../images/priority/Waterfowl.png";
import waterquality from "../../../images/priority/WaterQuality.png";
import winderosion from "../../../images/priority/WindErosion.png";
import "../style.css";

function Step4({ setRankingScore }) {
  const priorityPreference = secureLocalStorage.getItem("priority-preference");
  const selectedCheckboxes = priorityPreference.priorities;
  const allPriorities = [
    {
      src: winderosion,
      alt: "winderosion",
      label: "Wind Erosion",
      value: "winderosion",
      qContent:
        "Wind erosion causes damage to land by transporting soil, which can cause soil loss, dryness, and soil deterioration.",
    },
    {
      src: watererosion,
      alt: "watererosion",
      label: "Water Erosion",
      value: "watererosion",
      qContent:
        "Erosion of soil by running water through fields or along stream banks.",
    },
    {
      src: soilhealth,
      alt: "soilhealth",
      label: "Soil Health",
      value: "soilhealth",
      qContent:
        "Water quality concerns may result from compaction, organic matter depletion, excess salts and chemicals nutrients, and/or aggregate instability.",
    },
    {
      src: excesswind,
      alt: "excesswind",
      label: "Excess Wind",
      value: "excesswind",
      qContent:
        "Wind can lead to problems with soil erosion, snowdrift, and inefficient energy use.",
    },
    {
      src: drought,
      alt: "drought",
      label: "Drought",
      value: "drought",
      qContent:
        "Lack of water can be caused by weather patterns, water depletion, and inefficient use of irrigation water.",
    },
    {
      src: watertemperature,
      alt: "watertemperature",
      label: "Water Temperature",
      value: "elevatedwatertemperature",
      qContent:
        "High surface water temperatures can cause problems for aquatic life and water quality.",
    },

    {
      src: flooding,
      alt: "flooding",
      label: "Flooding",
      value: "flooding",
      qContent:
        "Ponding, flooding, and other drainage issues caused by excess water in soil.",
    },
    {
      src: snowdrift,
      alt: "snowdrift",
      label: "Snow Drift",
      value: "snowdrift",
      qContent:
        "Drifted snow that can prohibit humans and animals from accessing structures or features.",
    },
    {
      src: waterquality,
      alt: "waterquality",
      label: "Water Quality",
      value: "waterquality",
      qContent:
        "Water quality concerns may result from excess nutrients, pathogens, chemicals, pesticides, and/or pollutants in water supply.",
    },

    {
      src: aquatic,
      alt: "aquatic",
      label: "Aquatic",
      value: "aquatic",
      qContent: "Fish and other aquatic wildlife.",
    },
    {
      src: biggame,
      alt: "biggame",
      label: "Big Game",
      value: "biggame",
      qContent: "Deer, elk, pigs, bears, and other wildlife hunted for sport.",
    },
    {
      src: grasslandbirds,
      alt: "grasslandbirds",
      label: "Grassland Birds",
      value: "grasslandbirds",
      qContent:
        "Birds, such as quail and pheasant, that require grassland cover.",
    },
    {
      src: forestbirds,
      alt: "forestbirds",
      label: "Forest Birds",
      value: "forestbirds",
      qContent: "Birds, such as turkey, that require forest cover.",
    },

    {
      src: pollinators,
      alt: "pollinators",
      label: "Pollinators",
      value: "pollinators",
      qContent:
        "Pollinators include honeybees, butterflies, birds, bats and other animals.",
    },
    {
      src: waterfowl,
      alt: "waterfowl",
      label: "Waterfowl",
      value: "waterfowl",
      qContent: "Ducks, geese, and swans that require water or wetland cover.",
    },
    {
      src: carbonstorage,
      alt: "carbonstorage",
      label: "Carbon Storage",
      value: "carbonstorage",
      qContent:
        "Storage and sequestration of carbon dioxide from the atmosphere.",
    },
  ];

  const [priorityTags, setPriorityTags] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(useSensor(PointerSensor));

  const Placeholder = forwardRef(
    ({ id, withOpacity, isDragging, style, ...props }, ref) => {
      const inlineStyles = {
        opacity: withOpacity ? "0.5" : "1",
        transformOrigin: "50% 50%",
        height: "120px",
        width: "120px",
        padding: "10px",
        borderRadius: "10px",
        cursor: isDragging ? "grabbing" : "grab",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: isDragging
          ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
          : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
        transform: isDragging ? "scale(1.05)" : "scale(1)",
        ...style,
      };

      return (
        <div ref={ref} style={inlineStyles} {...props}>
          {"Drag to move to the front of others"}
        </div>
      );
    }
  );

  const DraggableTag = ({ priority, ...props }) => {
    const { tag } = props;
    const { listeners, setNodeRef, transform, transition, isDragging } =
      useSortable({
        id: tag.id,
      });

    const commonStyle = {
      cursor: "move",
      transition: "unset",
    };

    const style = transform
      ? {
          ...commonStyle,
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          transition: isDragging ? "unset" : transition,
        }
      : commonStyle;

    let priorityDataArray = allPriorities.filter(
      (item) => item.value === priority
    );
    let priorityData =
      priorityDataArray.length > 0 ? priorityDataArray[0] : null;

    return (
      priorityData && (
        <Col ref={setNodeRef} {...listeners}>
          <div className="tag-container" value={priorityData.value}>
            <img
              className="tag-image"
              src={priorityData.src}
              alt={priorityData.alt}
            />
            <Popover
              overlayStyle={{
                maxWidth: "20vw",
              }}
              placement="topRight"
              content={priorityData.qContent}
              trigger="hover"
            >
              <span className="pa">
                <QuestionCircleOutlined />
              </span>
            </Popover>
            <div className="tag-label">{priorityData.label}</div>
          </div>
        </Col>
      )
    );
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      setPriorityTags((data) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  useEffect(() => {
    const landCoverList = ["aquaculture", "cropland", "pasture", "wetland"];
    let priorityCheckboxes = selectedCheckboxes.filter(
      (item) => !landCoverList.includes(item)
    );
    setPriorityTags(
      priorityCheckboxes.map((priority, index) => {
        return {
          id: index + 1,
          text: priority,
        };
      })
    );
  }, [selectedCheckboxes]);

  useEffect(() => {
    let rankingScoreList = priorityTags.map((item, index) => {
      let score = 0;
      if (index === 0) {
        score = 3;
      } else if (index === 1) {
        score = 2;
      } else if (index === 2) {
        score = 1;
      }
      return {
        priority: item.text,
        score: score,
      };
    });
    setRankingScore(rankingScoreList);
  }, [priorityTags]);

  return (
    <>
      <Row className="mt-1">
        <p>
          <b>
            4) Which conservation priorities would you address the most? Please
            drag the top three to the corresponding boxes.
          </b>
        </p>
      </Row>
      <Row className="mt-1  priority-row">
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={priorityTags}
            strategy={horizontalListSortingStrategy}
          >
            {priorityTags.map((item, index) => (
              <>
                {index === 0 && (
                  <Col className="ranking-box ranking-box-first">
                    <b className="ranking-label">1st Priority</b>
                    <DraggableTag
                      priority={item.text}
                      tag={item}
                      key={item.id}
                    />
                  </Col>
                )}
                {index === 1 && (
                  <Col className="ranking-box">
                    <b className="ranking-label">2nd Priority</b>
                    <DraggableTag
                      priority={item.text}
                      tag={item}
                      key={item.id}
                    />
                  </Col>
                )}
                {index === 2 && (
                  <>
                    <Col className="ranking-box">
                      <b className="ranking-label">3rd Priority</b>
                      <DraggableTag
                        priority={item.text}
                        tag={item}
                        key={item.id}
                      />
                    </Col>
                    <Col span={24}>
                      <hr />
                    </Col>
                  </>
                )}
                {index > 2 && (
                  <DraggableTag priority={item.text} tag={item} key={item.id} />
                )}
              </>
            ))}
          </SortableContext>
          <DragOverlay style={{ transformOrigin: "0 0 " }}>
            {activeId ? <Placeholder id={activeId} isDragging /> : null}
          </DragOverlay>
        </DndContext>
      </Row>
      <Row></Row>
    </>
  );
}
export default Step4;
