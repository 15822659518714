import React, { useEffect, useState } from "react";
import { Button, Card, Rate, Tooltip } from "antd";
import { CheckSquareOutlined, SelectOutlined } from "@ant-design/icons";
import { FaAngleDoubleRight, FaHeart } from "react-icons/fa";
import {
  TbHexagonLetterC,
  TbHexagonLetterP,
  TbHexagonLetterR,
  TbHexagonLetterS,
  TbSquareLetterC,
  TbSquareLetterG,
  TbChevronCompactDown,
} from "react-icons/tb";
import secureLocalStorage from "react-secure-storage";

// Images List

//step2 Images
import aquaculture from "../../../images/landscape/AquaculturePond.png";
import cropland from "../../../images/landscape/Cropland.png";
import timber from "../../../images/landscape/Timber.png";
import pasture from "../../../images/landscape/Pasture.png";
import wetland from "../../../images/landscape/Wetland.png";

//step3 Images
import aquatic from "../../../images/priority/Aquatic.png";
import biggame from "../../../images/priority/BigGame.png";
import carbonstorage from "../../../images/priority/CarbonStorage.png";
import drought from "../../../images/priority/Drought.png";
import elevatedwatertemperature from "../../../images/priority/WaterTemperature.png";
import excesswind from "../../../images/priority/ExcessWind.png";
import flooding from "../../../images/priority/Flooding.png";
import forestbirds from "../../../images/priority/ForestBirds.png";
import grasslandbirds from "../../../images/priority/GrasslandBirds.png";
import pollinators from "../../../images/priority/Pollinators.png";
import snowdrift from "../../../images/priority/SnowDrift.png";
import soilhealth from "../../../images/priority/SoilHealth.png";
import watererosion from "../../../images/priority/WaterErosion.png";
import waterfowl from "../../../images/priority/Waterfowl.png";
import waterquality from "../../../images/priority/WaterQuality.png";
import winderosion from "../../../images/priority/WindErosion.png";

import "../style.css";

const PracticeCard = ({
  practice,
  currentData,
  currentPriorities,
  selectedTopPracticeList,
  selectedFips,
  layout,
  selectable,
  preferredPractice,
  setPreferredPractice,
  setPreferredSignups,
  setPreferredIncentives,
  userFavoritePractices,
  favOnchange,
  listView,
  selected,
  printView,
}) => {
  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland",
  };

  const step3Items = [
    { key: "aquatic", src: aquatic },
    { key: "biggame", src: biggame },
    { key: "carbonstorage", src: carbonstorage },
    { key: "drought", src: drought },
    { key: "elevatedwatertemperature", src: elevatedwatertemperature },
    { key: "excesswind", src: excesswind },
    { key: "flooding", src: flooding },
    { key: "forestbirds", src: forestbirds },
    { key: "grasslandbirds", src: grasslandbirds },
    { key: "pollinators", src: pollinators },
    { key: "snowdrift", src: snowdrift },
    { key: "soilhealth", src: soilhealth },
    { key: "watererosion", src: watererosion },
    { key: "waterfowl", src: waterfowl },
    { key: "waterquality", src: waterquality },
    { key: "winderosion", src: winderosion },
  ];

  const step2Images = [
    "aquaculture",
    "cropland",
    "timber",
    "pasture",
    "wetland",
  ];

  const [cardClass, setCardClass] = useState("");

  const scrollIndicatorItems = currentPriorities
    ? step3Items.filter(({ key }) => currentPriorities[key])
    : [];

  const isLoggedIn = secureLocalStorage.getItem("token");

  useEffect(() => {
    setCardClass(classNameSelector());
  }, [preferredPractice]);

  const practiceTitle = practice.title.split("-")[0];

  const classNameSelector = () => {
    let myClassName = "practice-card";

    if (listView === "List") {
      myClassName += " list-view";
      if (selected) myClassName += " selected";
    } else if (listView === "Grid") {
      if (selected) myClassName += " selected";
      myClassName += " practice-card-vertical";
    } else {
      if (selected) myClassName += " selected";
      myClassName += " practice-card-horizontal";
    }
    return myClassName;
  };

  return (
    <Card
      className={cardClass}
      title={
        <>
          <p className="practice-number">
            {practice.title.split("-")[0]}{" "}
            <div style={{ float: "right" }}>
              {!!practice.score && practice.score >= 5 && (
                <span className="practice-best-match">Best Match</span>
              )}
              {!!practice.score &&
                practice.score >= 3 &&
                practice.score < 5 && (
                  <span className="practice-good-match">Good Match</span>
                )}
              {!!practice.score &&
                practice.score >= 1 &&
                practice.score < 3 && (
                  <span className="practice-fair-match">Fair Match</span>
                )}{" "}
              {isLoggedIn !== null && (
                <Rate
                  character={
                    <FaHeart
                      color={
                        userFavoritePractices.includes(practice.title)
                          ? "coral"
                          : "lightgray"
                      }
                      style={{ verticalAlign: "middle" }}
                    />
                  }
                  count="1"
                  value={userFavoritePractices.includes(practice.title) ? 1 : 0}
                  onChange={(val) =>
                    favOnchange(val, practice.title + "#" + practice.pdflink)
                  }
                />
              )}
            </div>
          </p>
          <p className="practice-title">{practice.title.split("-")[1]}</p>
        </>
      }
      headStyle={{
        backgroundColor: "#5D1725",
        color: "white",
      }}
    >
      {selectedTopPracticeList &&
        selectedTopPracticeList.includes(
          practice.title.split("-")[0].trim()
        ) && (
          <div
            class={
              listView === "Compact" ? "most-common-compact" : "most-common"
            }
          >
            Most Common
          </div>
        )}

      {currentPriorities && (
        <p className="step2_images">
          <b>Land Cover:</b>
          <br />
          <Tooltip placement="topLeft" title={getLabels.pasture}>
            {currentPriorities.pasture && (
              <img className="img_selected_cb" src={pasture} />
            )}
          </Tooltip>
          <Tooltip placement="topLeft" title={getLabels.aquaculture}>
            {currentPriorities.aquaculture && (
              <img className="img_selected_cb" src={aquaculture} />
            )}
          </Tooltip>
          <Tooltip placement="topLeft" title={getLabels.timber}>
            {currentPriorities.timber && (
              <img className="img_selected_cb" src={timber} />
            )}
          </Tooltip>
          <Tooltip placement="topLeft" title={getLabels.cropland}>
            {currentPriorities.cropland && (
              <img className="img_selected_cb" src={cropland} />
            )}
          </Tooltip>
          <Tooltip placement="topLeft" title={getLabels.wetland}>
            {currentPriorities.wetland && (
              <img className="img_selected_cb" src={wetland} />
            )}
          </Tooltip>
        </p>
      )}

      {currentPriorities && (
        <p className="step3_images">
          <b>Priorities:</b>
          <br />
          {scrollIndicatorItems.length > 0 ? (
            scrollIndicatorItems.map(({ key, src }) => (
              <Tooltip key={key} placement="topLeft" title={getLabels[key]}>
                <img className="img_selected_cb" src={src} alt={key} />
              </Tooltip>
            ))
          ) : (
            <span>No priorities selected.</span>
          )}
          {scrollIndicatorItems.length > 3 && !printView && (
            <div className="scroll-indicator">
              <TbChevronCompactDown />
            </div>
          )}
        </p>
      )}

      {currentData && (
        <p className="signup-icons">
          <b>Signups: </b>
          <br />
          {currentData.general && (
            <Tooltip title="Available In General Signup">
              <TbSquareLetterG size="2em" color="maroon" />
            </Tooltip>
          )}
          {currentData.continous && (
            <Tooltip title="Available In Continuous Signup">
              <TbSquareLetterC size="2em" color="maroon" />
            </Tooltip>
          )}
        </p>
      )}

      {currentData && (
        <p className="incentive-icons">
          <b>Incentives: </b>
          <div className="incentive-icons-inner">
            {currentData["32_5_p_sip"] && (
              <Tooltip title="Signup Incentive">
                <TbHexagonLetterS size="2em" color="maroon" />
                <b className="incentive-percent">32.5%</b>
              </Tooltip>
            )}
            {currentData["20_p_rr_incentive"] && (
              <Tooltip title="Rental Rate Incentive">
                <TbHexagonLetterR size="2em" color="maroon" />
                <b className="incentive-percent">20%</b>
              </Tooltip>
            )}
            {currentData["50_p_pip"] && (
              <Tooltip title="Practice Incentive">
                <TbHexagonLetterP size="2em" color="maroon" />
                <b className="incentive-percent">50%</b>
              </Tooltip>
            )}
            {currentData["climate_smrt_incentive"] !== ("false" || "NA") && (
              <Tooltip title="Climate Smart Incentive">
                <TbHexagonLetterC size="2em" color="maroon" />
                <b className="incentive-percent">
                  {currentData["climate_smrt_incentive"] + "%"}
                </b>
              </Tooltip>
            )}
          </div>
        </p>
      )}

      {/* {selectable && setPreferredPractice && (
        <Button
          className={
            preferredPractice === practice.title.split("-")[0]
              ? "practice-select-button-selected"
              : "practice-select-button"
          }
          onClick={() => {
            setPreferredPractice(practice.title.split("-")[0]);
            setPreferredSignups({
              G: currentData.general,
              C: currentData.continous,
            });
            setPreferredIncentives({
              S: currentData["32_5_p_sip"],
              R: currentData["20_p_rr_incentive"],
              P: currentData["50_p_pip"],
              C: currentData["climate_smrt_incentive"],
            });
          }}
        >
          {preferredPractice === practice.title.split("-")[0] ? (
            <>
              <b>Selected </b>
              <CheckSquareOutlined />
            </>
          ) : (
            <>
              <b>Select </b>
              <SelectOutlined />
            </>
          )}
        </Button>
      )} */}

      <p className={"detail-right"}>
        <a
          href={`/Practice/${selectedFips}/${btoa(practice.pdflink)}/${btoa(
            practice.title
          )}`}
          target="_blank"
          className="detail-button"
          rel="noreferrer"
        >
          <b>Details</b> <FaAngleDoubleRight />
        </a>
      </p>
    </Card>
  );
};
export default PracticeCard;
