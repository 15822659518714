import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spin,
  Tooltip,
  Rate,
  notification,
  Popconfirm,
  Form,
  Input,
  Switch,
  Dropdown,
  message,
} from "antd";
import {
  HeartOutlined,
  SaveOutlined,
  PrinterOutlined,
  ClearOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { GiHighGrass } from "react-icons/gi";
import {
  IoFilter,
  IoGrid,
  IoListOutline,
  IoPrintOutline,
} from "react-icons/io5";
import { PiFarm } from "react-icons/pi";
import secureLocalStorage from "react-secure-storage";
import { ModuleContext } from "../../context/Context";
import PracticeCard from "./Components/PracticeCard";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WizardForm from "../ExplorebyGoals/wizardForm";
import hostURL from "../../Common/constants";
import "./style.css";
import {
  getPracticeIncentives,
  getPracticeLandCovers,
  getPracticePriorities,
} from "../../utils/helperFunctions";

const PracticeList = ({
  setPracticeNumber,
  userFavoritePractices,
  setUserFavoritePractices,
}) => {
  const [wizardStep, setWizardStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCounty, setselectedCounty] = useState(null);
  const [countyListData, setCountyListData] = useState(null);
  const [step2Priorities, setStep2Priorities] = useState([]);
  const [step3Priorities, setStep3Priorities] = useState([]);
  const [clickedState, setClickedState] = useState(null);
  const [clickedCounty, setClickedCounty] = useState(null);
  const [clickedFips, setClickedFips] = useState(null);
  const [selectedFips, setSelectedFips] = useState(null);
  const [selectedPracticeList, setselectedPracticeList] = useState(null);
  const [selectedTopPracticeList, setselectedTopPracticeList] = useState(null);
  const [selectedPracticeData, setSelectedPracticeData] = useState(null);
  const [filteredPractices, setFilteredPractices] = useState(null);
  const [filteredPracticesData, setFilteredPracticesData] = useState([]);
  const [filteredPracticesPriorities, setFilteredPracticesPriorities] =
    useState([]);
  const [filteredPracticesNumber, setFilteredPracticesNumber] = useState(0);
  const [step2CheckedTypes, setstep2CheckedTypes] = useState([]);
  const [step3CheckedTypes, setstep3CheckedTypes] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [rankingScore, setRankingScore] = useState([]);
  const [notselected, setNotselected] = useState(false);
  const [listView, setListView] = useState("Grid");
  const [isSavePopOpen, setIsSavePopOpen] = useState(false);

  const { priorityPreference, updatePriorityPreference } =
    useContext(ModuleContext);

  const isLoggedIn = secureLocalStorage.getItem("token");

  const prev = () => {
    setWizardStep(wizardStep - 1);
  };

  const printPracticeArray = [];

  const addPrintPracticeArray = (practice) => {
    if (!!practice && !printPracticeArray.includes(practice)) {
      printPracticeArray.push(practice);
    }
  };

  const removePrintPracticeArray = (practice) => {
    if (!!practice && printPracticeArray.includes(practice)) {
      const index = printPracticeArray.indexOf(practice);
      if (index > -1) {
        printPracticeArray.splice(index, 1);
      }
    }
  };

  const handleMenuClick = (e) => {
    if (e.key === "1") setListView("Grid");
    if (e.key === "2") setListView("List");
    if (e.key === "3") {
      const printFilterOptions = {
        landCover: step2CheckedTypes,
        priorities: step3CheckedTypes,
        state: selectedState,
        county: selectedCounty,
      };
      secureLocalStorage.setItem(
        "printPracticeData",
        JSON.stringify(printPracticeArray)
      );
      secureLocalStorage.setItem(
        "printFilterSelections",
        JSON.stringify(printFilterOptions)
      );
      const origin = "http://localhost:3000";
      const newWindow = window.open(
        "/PracticeList/Print",
        "_blank",
        "rel=noopener noreferrer"
      );
      newWindow?.contentWindow.postMessage("Hello!!", origin);
    }
  };

  const items = [
    {
      label: "Grid View",
      key: "1",
      icon: <IoGrid />,
    },
    {
      label: "List View",
      key: "2",
      icon: <IoListOutline />,
    },
    {
      label: "Print View",
      key: "3",
      icon: <IoPrintOutline />,
    },
  ];

  const next = () => {
    if (selectedState != null && selectedCounty != null) {
      setNotselected(false);
      setWizardStep(wizardStep + 1);
    } else {
      setNotselected(true);
    }
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const landCoverTypesArray = [
    "aquaculture",
    "cropland",
    "timber",
    "pasture",
    "wetland",
  ];

  const navigate = useNavigate();

  const tagColors = [
    "#640000",
    "#731815",
    "#822b25",
    "#913c36",
    "#a04d47",
    "#ae5e58",
    "#bc6f6a",
    "#ca807c",
    "#d7928f",
    "#e5a3a2",
    "#f2b6b5",
    "#ffc8c8",
  ];

  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland",
  };

  const step2Images = [
    "aquaculture",
    "cropland",
    "timber",
    "pasture",
    "wetland",
  ];

  const step3Images = [
    "aquatic",
    "biggame",
    "carbonstorage",
    "drought",
    "watertemperature",
    "excesswind",
    "flooding",
    "forestbirds",
    "grasslandbirds",
    "pollinators",
    "snowdrift",
    "soilhealth",
    "watererosion",
    "waterfowl",
    "waterquality",
    "winderosion",
  ];

  const authToken = secureLocalStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    fetch(hostURL + "api/states", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const statesListData = json.map((item) => {
          return { value: item.scode, label: item.sname };
        });
        setStateList(statesListData);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (filteredPractices) {
      (async () => {
        setLoading(true);
        let metaData = [];
        let priorityData = [];

        await Promise.all(
          filteredPractices.map(async (practice) => {
            const prTitle = practice.title;
            const prCode = prTitle.split("-")[0].trim();
            const prName = prTitle.split(/-(.*)/)[1].trim();
            await fetch(
              `${hostURL}api/natpractices/${prCode}/${encodeURIComponent(
                prName
              )}`
            )
              .then((response) => {
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json();
                }
              })
              .then((json) => {
                if (json) {
                  metaData.push(json);
                }
              })
              .catch((error) => console.error(error));
          })
        );

        await Promise.all(
          filteredPractices.map(async (practice) => {
            const prTitle = practice.title;
            const prCode = prTitle.split("-")[0].trim();
            const prName = prTitle.split(/-(.*)/)[1].trim();
            await fetch(
              `${hostURL}api/crppriorities/${prCode}/${encodeURIComponent(
                prName
              )}`
            )
              .then((response) => {
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json();
                }
              })
              .then((json) => {
                if (json) {
                  priorityData.push(json);
                }
              })
              .catch((error) => console.error(error));
          })
        );

        setFilteredPracticesData(metaData);
        setFilteredPracticesPriorities(priorityData);
        setLoading(false);
      })();
    }
  }, [filteredPractices]);

  useEffect(() => {
    // updatePracticePreference({
    //   state: selectedState,
    //   county: selectedCounty,
    //   fips: selectedFips,
    //   practices: filteredPractices,
    //   practiceList: selectedPracticeList
    // });
  }, [
    selectedState,
    selectedCounty,
    filteredPractices,
    selectedPracticeList,
    step2CheckedTypes,
    step3CheckedTypes,
    step2Priorities,
    step3Priorities,
  ]);

  const onStateChange = (selectedState) => {
    setLoading(true);
    setSelectedState(selectedState);
    setselectedCounty(null);
    setFilteredPractices(null);
    fetch(hostURL + "api/counties?state=" + selectedState)
      .then((response) => response.json())
      .then((json) => {
        const countiesListData = json.map((item) => {
          return { value: item.cname, label: item.cname };
        });
        setCountyList(countiesListData);
        setCountyListData(json);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const onCountyChange = (selectedCounty) => {
    setLoading(true);
    setFilteredPractices(null);
    setselectedCounty(selectedCounty);
    let queryState =
      clickedState && clickedState !== selectedState
        ? clickedState
        : selectedState;
    fetch(
      `${hostURL}api/${queryState.toLowerCase()}countypractices/${queryState}/${selectedCounty}`
    )
      .then((response) => response.json())
      .then((json) => {
        const respData = json[0];
        setselectedPracticeList(json);
        setSelectedFips(respData.fips);
        let toShowData =
          respData &&
          JSON.parse(
            respData.assetdata.substring(1, respData.assetdata.length - 1)
          );
        setFilteredPractices(toShowData);

        if (authToken) {
          // myCommonApiCall.storeSearchData(
          //   { queryState, selectedCounty },
          //   "step#" + wizardStep
          // );
          getUserFavorites();

          // userFavoritePractices
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
    setLoading(false);
  };

  const storeSearchData = (values, sroute) => {
    const searchedData = {
      selectedState,
      selectedCounty,
      step2CheckedTypes,
      step3CheckedTypes,
      rankingScore,
    };
    setIsSavePopOpen(false);
    // debugger;
    const formData = {
      userId: secureLocalStorage.getItem("userRef"),
      route: window.location.href,
      subRoute: sroute,
      formData: JSON.stringify(searchedData),
      stype: "explorePractices",
      mySearchName: values.SaveKey,
    };

    setLoading(true);
    fetch(hostURL + "api/usersearchdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        openNotificationWithIcon(
          "success",
          "Success",
          "Saved Your Search Criteria"
        );
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon("error", "Error", "Try Again Later");
      });
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getUserFavorites = () => {
    fetch(
      `${hostURL}api/userfavourite/${secureLocalStorage.getItem(
        "userRef"
      )}/${selectedState}/${selectedCounty}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        const respData = Array.isArray(json)
          ? json.map((item) => item.favTitle)
          : json.data && Array.isArray(json.data)
          ? json.data.map((item) => item.favTitle)
          : [];
        setUserFavoritePractices(respData);
      });
  };

  const onPrioritiesSelect = (selectedPriorities) => {
    // Wizard steps start from 0
    if (wizardStep === 1) {
      setstep2CheckedTypes(selectedPriorities);
      updatePriorityPreference({
        ...priorityPreference,
        landCoverTypes: selectedPriorities,
      });
      secureLocalStorage.setItem("priority-preference", {
        ...priorityPreference,
        landCoverTypes: selectedPriorities,
      });
    } else if (wizardStep === 2) {
      setstep3CheckedTypes(selectedPriorities);
      updatePriorityPreference({
        ...priorityPreference,
        priorities: selectedPriorities,
      });
      secureLocalStorage.setItem("priority-preference", {
        ...priorityPreference,
        priorities: selectedPriorities,
      });
    }
  };

  const handleFilter = () => {
    setFilterOpen(true);
  };

  const handleOk = () => {
    setFilterOpen(false);
  };

  const handleCancel = () => {
    setFilterOpen(false);
  };

  const getPracticeReport = () => {
    navigate("/PracticeReport");
  };

  const favOnchange = (val, title) => {
    const pTitle = title.split("#")[0];
    const pLink = title.split("#")[1];
    if (val === 1) {
      const formData = {
        userId: secureLocalStorage.getItem("userRef"),
        favTitle: pTitle,
        favPdfLink: pLink,
        state: selectedState,
        county: selectedCounty,
      };

      setLoading(true);
      fetch(hostURL + "api/userfavourite", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((json) => {
          getUserFavorites();
          openNotificationWithIcon(
            "success",
            "Success",
            "Added to your favorite practices"
          );
          setLoading(false);
        })
        .catch((error) => {
          openNotificationWithIcon("error", "Error", "Try Again Later");
        });
    } else if (val == 0) {
      const formData = {
        userId: secureLocalStorage.getItem("userRef"),
        favTitle: pTitle,
        state: selectedState,
        county: selectedCounty,
      };

      setLoading(true);
      fetch(
        `${hostURL}api/userfavourite/${formData.userId}/${formData.favTitle}/${formData.state}/${formData.county}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authToken,
          },
          body: JSON.stringify(formData),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          getUserFavorites();
          openNotificationWithIcon(
            "success",
            "Success",
            "Removed from your favorite practices"
          );
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          openNotificationWithIcon("error", "Error", "Try Again Later");
        });
    }
  };

  const resetFilterSearch = () => {
    // setSelectedState(null);
    // setselectedCounty(null);
    // setstep2CheckedTypes([]);
    // setstep3CheckedTypes([]);
    // setFilteredPractices([]);
    // setWizardStep(0);
    // setClickedFips(null);
    // setSelectedFips(null);
    window.location.reload();
  };

  useEffect(() => {
    if (clickedFips !== selectedFips) {
      setSelectedState(clickedState);
      setselectedCounty(clickedCounty);
      setSelectedFips(clickedFips);
      if (clickedState !== selectedState) {
        onStateChange(clickedState);
        onCountyChange(clickedCounty);
      } else {
        onCountyChange(clickedCounty);
      }
    }
  }, [clickedFips]);

  useEffect(() => {
    if (countyListData) {
      let countyDataArray = countyListData.filter(
        (item) => item.cname === selectedCounty
      );
      let assetData =
        countyDataArray.length > 0 ? countyDataArray[0].assetdata : null;
      let topPracticeList = assetData
        ? assetData.replace('"', "").split(",")
        : null;
      setselectedTopPracticeList(topPracticeList);
    }
  }, [countyListData, selectedCounty]);

  useEffect(() => {
    let allCheckedTypes = step2CheckedTypes.concat(step3CheckedTypes);
    setSelectedCheckboxes(allCheckedTypes);
  }, [step2CheckedTypes, step3CheckedTypes]);

  useEffect(() => {
    if (
      filteredPractices &&
      filteredPracticesPriorities.length > 0 &&
      rankingScore.length > 0
    ) {
      let filteredPracticesScores = filteredPracticesPriorities.map((data) => {
        let totalScoreArray = rankingScore.map((item) => {
          let score = data[item.priority] ? item.score : 0;
          return score;
        });
        let totalScore = totalScoreArray.reduce((a, b) => {
          return a + b;
        }, 0);
        return {
          pnumber: data.pnumber,
          score: totalScore,
        };
      });

      let scoredFilteredPractices = filteredPractices.map((practice) => {
        let scoreArray = filteredPracticesScores.filter(
          (item) => item.pnumber === practice.title.split("-")[0].trim()
        );
        let score = scoreArray.length > 0 ? scoreArray[0].score : 0;
        return {
          title: practice.title,
          pdflink: practice.pdflink,
          notes: practice.notes,
          score: score,
        };
      });

      let sortedFilteredPractices = scoredFilteredPractices.sort(
        (a, b) => b.score - a.score
      );
      setFilteredPractices(sortedFilteredPractices);
    }
  }, [rankingScore]);

  const [form] = Form.useForm();

  const showPopconfirm = () => {
    setIsSavePopOpen(true);
  };

  const saveSearchPopClose = () => {
    form.resetFields();
    setIsSavePopOpen(false);
  };

  const focusRef = useRef(null);

  useEffect(() => {
    focusRef.current.focus();
  }, [filterOpen]);

  return (
    <>
      <Header />
      {contextHolder}
      <Spin spinning={loading}>
        <Modal
          centered={true}
          className="filter-modal"
          open={filterOpen}
          width="80vw"
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          footer={[
            wizardStep > 0 ? (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Back
              </Button>
            ) : (
              ""
            ),
            wizardStep < 3 ? (
              <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => next()}
              >
                Next
              </Button>
            ) : (
              ""
            ),
            wizardStep === 3 ? (
              <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => setFilterOpen(false)}
              >
                Confirm
              </Button>
            ) : (
              ""
            ),
          ]}
        >
          <WizardForm
            className="filter-wizard-form"
            wizardStep={wizardStep}
            setWizardStep={setWizardStep}
            stateList={stateList}
            selectedState={selectedState}
            stateChange={onStateChange}
            countyList={countyList}
            selectedCounty={selectedCounty}
            countyChange={onCountyChange}
            setClickedState={setClickedState}
            setClickedCounty={setClickedCounty}
            setClickedFips={setClickedFips}
            selectedFips={selectedFips}
            selectedPriorities={onPrioritiesSelect}
            selectedCheckboxes={selectedCheckboxes}
            setFilterOpen={setFilterOpen}
            setRankingScore={setRankingScore}
            notselected={notselected}
          />
        </Modal>
        <div className="practice-list">
          <div className="filter-container">
            <div className="filter-buttons-container">
              <Button className="filter-button" onClick={handleFilter}>
                <b>
                  <IoFilter /> Filters
                </b>
              </Button>
              <Popconfirm
                title="Clear Filters"
                description="Are you sure you want to clear the Filter Results and start over?"
                onConfirm={resetFilterSearch}
                okText="Yes"
                cancelText="No"
              >
                <Button className="filter-button">
                  <b>
                    <ClearOutlined /> Start Over
                  </b>
                </Button>
              </Popconfirm>
            </div>

            <div className="filter-results">
              <h3>
                Available Practices:
                {
                  document.getElementsByClassName("practice-card-vertical")
                    .length
                }
              </h3>
              <h3>
                {step2CheckedTypes.length
                  ? "Selected Land Cover Types: "
                  : null}
                {step2CheckedTypes.map((type) => (
                  <b className="filter-tag">{getLabels[type]}</b>
                ))}
              </h3>
              <h3>
                {step3CheckedTypes.length ? "Selected Priorities: " : null}
                {step3CheckedTypes.map((type) => (
                  <b className="filter-tag">{getLabels[type]}</b>
                ))}
              </h3>
            </div>

            <div className="filter-options-container">
              <Dropdown menu={menuProps}>
                <div
                  onClick={(e) => e.preventDefault()}
                  className="list-view-button"
                >
                  <div className="list-view-button-div">
                    {listView === "List" ? (
                      <IoListOutline size={20} />
                    ) : listView === "Grid" ? (
                      <IoGrid />
                    ) : (
                      <IoPrintOutline />
                    )}
                    <b>{` ${listView} View`}</b>
                  </div>
                  <DownOutlined />
                </div>
              </Dropdown>
              <Button
                className="list-view-button"
                onClick={() => navigate("/Map")}
              >
                <b>
                  <GiHighGrass /> Check Field Eligibility
                </b>
              </Button>
              <Row>
                {isLoggedIn !== null && wizardStep === 3 && (
                  <Button className="filter-button" onClick={showPopconfirm}>
                    <b>
                      <SaveOutlined /> Save This Search
                    </b>
                  </Button>
                )}
                {isLoggedIn !== null && (
                  <Button className="filter-button" href="/UserProfile">
                    <b>
                      <HeartOutlined /> Favorite Practices
                    </b>
                  </Button>
                )}
              </Row>
            </div>
          </div>
          <Row
            ref={focusRef}
            tabIndex={!filterOpen && 0}
            justify="center"
            className={
              listView === "List"
                ? "filter-practice-row list-view"
                : "filter-practice-row"
            }
          >
            {filteredPractices &&
              filteredPractices.length > 0 &&
              selectedTopPracticeList &&
              filteredPractices.map((practice, index) => {
                let currentDataArray = filteredPracticesData.filter(
                  (practiceData) =>
                    practiceData.pnumber === practice.title.split("-")[0].trim()
                );
                let currentData =
                  currentDataArray.length > 0 ? currentDataArray[0] : null;
                let currentPrioritiesArray = filteredPracticesPriorities.filter(
                  (data) => data.pnumber === practice.title.split("-")[0].trim()
                );

                let currentPriorities =
                  currentPrioritiesArray.length > 0
                    ? currentPrioritiesArray[0]
                    : null;
                let currentPrioritiesList = currentPriorities
                  ? Object.keys(currentPriorities).filter(
                      (key) => currentPriorities[key] === true
                    )
                  : [];
                let landCoverIntersection =
                  currentPrioritiesList.length > 0
                    ? currentPrioritiesList.filter((landcover) =>
                        step2CheckedTypes.includes(landcover)
                      )
                    : [];
                let priorityIntersection =
                  currentPrioritiesList.length > 0
                    ? currentPrioritiesList.filter((priority) =>
                        step3CheckedTypes.includes(priority)
                      )
                    : [];
                let listPracticePriorities = getPracticePriorities(
                  currentPrioritiesList
                );
                let listPracticeIncentives = getPracticeIncentives(currentData);
                let listPracticeLandCovers = getPracticeLandCovers(
                  currentPrioritiesList
                );
                let mostCommonLabel = selectedTopPracticeList.includes(
                  practice.title.split("-")[0].trim()
                );

                let showPracticeCard =
                  step2CheckedTypes.length === 0 &&
                  step3CheckedTypes.length === 0
                    ? true
                    : step2CheckedTypes.length === 0
                    ? priorityIntersection.length > 0
                      ? true
                      : false
                    : step3CheckedTypes.length === 0
                    ? landCoverIntersection.length > 0
                      ? true
                      : false
                    : landCoverIntersection.length > 0 &&
                      priorityIntersection.length > 0
                    ? true
                    : false;
                if (showPracticeCard) {
                  addPrintPracticeArray({
                    ...currentData,
                    practiceIncentives: listPracticeIncentives,
                    practicePriorities: listPracticePriorities,
                    practiceCovers: listPracticeLandCovers,
                    practiceScore: practice.score,
                    mostCommon: mostCommonLabel,
                  });
                }
                if (!showPracticeCard) {
                  removePrintPracticeArray(currentData);
                }
                return (
                  showPracticeCard && (
                    <Col
                      className="toOverrideAntD"
                      span={listView === "List" ? 12 : 5}
                    >
                      <PracticeCard
                        listView={listView}
                        practice={practice}
                        currentData={currentData}
                        currentPriorities={currentPriorities}
                        selectedTopPracticeList={selectedTopPracticeList}
                        selectedFips={selectedFips}
                        layout="vertical"
                        selectable={false}
                        userFavoritePractices={userFavoritePractices}
                        favOnchange={favOnchange}
                      />
                    </Col>
                  )
                );
              })}
          </Row>
          <Modal
            centered={true}
            title="Save My Search"
            open={isSavePopOpen}
            onOk={null}
            footer={null}
            onCancel={saveSearchPopClose}
          >
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              form={form}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={storeSearchData}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Save Search"
                name="SaveKey"
                rules={[
                  {
                    required: true,
                    message: "Please input your Save Search!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
        <Footer />
      </Spin>
    </>
  );
};
export default PracticeList;
